/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import useScrollPosition from "use-scroll-position";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn, faCog, faCoins, faHouse, faUser, faUsers, faWallet } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from "../../../context/ThemeContext";
import { translate } from "../../../utils/translate";
import { faLight, faSharp } from '@fortawesome/free-solid-svg-icons'; // Example light and sharp styles
import "../../../css/sidebar.scss";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const history = useHistory();
  const [path, setPath] = useState("");
  const [toggle, setToggle] = useState(false);
  const [clanguage, setcLanguage] = useState('English');
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    openMenuToggle
  } = useContext(ThemeContext);
  
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  // let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];

  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      return;
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      return;
    } else {
      aaabackdrop.classList.remove('d-none');
      aaa.classList.add("menu-toggle");
      return
    }
  }
  /// Path
  // let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];


  useEffect(() => {
    history.listen((location) => {
      console.log(location.pathname,"path")
      setPath(location.pathname);
    });
  }, [history]);



  let dashboard = ["/dashboard"];

  let setting = ["/setting", "/setting/change-password", "/setting/change-email", "/setting/change-transaction-password", "/setting/kyc"];
  let investment = ["/investment/new", "/investment/details", "/investment/portfolio",'/investment/cTraderAccount'];

  let profile = ["/profile"];
  let team = ["/team", "/team/total-team-investment", "/team/group-investment-summary", "/team/referral"];
  let wallet = ["/wallet", "/wallet/deposit", "/wallet/transfer", "/wallet/withdrawal", "/wallet/internalTransfer", "/wallet/history" ];
  let language = ["/language"];
  let gasfreecampaign = ["/gasfreecampaign/register" , "gasfreecampaign/status"]

  const closeSideBar = () => {
    openMenuToggle();
  }

  const closeMenu = () => {
    // var element = document.getElementById("main-wrapper");
    // element.classList.remove("menu-toggle");
    if(document.querySelector('body').getAttribute("data-sidebar-style")==='overlay'){
      document.querySelector(".nav-control").click();
    }
  }


  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashboard.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link to="/dashboard" className="ai-icon" onClick={() => closeMenu()}>
              <FontAwesomeIcon icon={faHouse} className="menu-icon" />
              <span className={`font-weight-700 font-15 nav-text`}>{translate(props.lang, "dashboard")}</span>
            </Link>
          </li>

          <li className={`${investment.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link className="ai-icon" to="/investment/cTraderAccount" onClick={() => closeMenu()}>
              <FontAwesomeIcon icon={faCoins} className="menu-icon" />
              <span className="nav-text forms">
                {translate(props.lang, "cTrader Account")}
              </span>
            </Link>
            {/* <ul>
              <li>
                <Link className={`${path === "/investment/portfolio" ? "mm-active menu-active" : ""}`} to="/investment/portfolio" onClick={() => closeMenu()}>
                  <span className="">{translate(props.lang, "Overview")}</span>
                </Link>
                <Link className={`${path === "/investment/new" ? "mm-active menu-active" : ""}`} to="/investment/new" onClick={() => closeMenu()}>
                  <span className="">{translate(props.lang, "New Investment")}</span>
                </Link>
                <Link className={`${path === "/investment/details" ? "mm-active menu-active" : ""}`} to="/investment/details" onClick={() => closeMenu()}>
                  <span className="">{translate(props.lang, "Investment Details")}</span>
                </Link>
                <Link className={`${path === "/investment/topupinvestment" ? "mm-active menu-active" : ""}`} to="/investment/topupinvestment" onClick={() => closeMenu()}>
                  <span className="">{translate(props.lang, "Top Up Investment")}</span>
                </Link>

              </li>
            </ul> */}
          </li>
          <li className={`${wallet.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link className="has-arrow ai-icon" to="#">
              <FontAwesomeIcon icon={faWallet} className="menu-icon" />
              <span className="nav-text forms">
                {translate(props.lang, "Wallet")}
              </span>
            </Link>
            <ul className={`${wallet.includes(path) ? "mm-show" : ""}`}>
              <li>
                <Link className={`${path === "/wallet" ? "mm-active menu-active" : ""}`} to="/wallet" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Overview")}</span>
                </Link>
                <Link className={`${path === "/wallet/deposit" ? "mm-active menu-active" : ""}`} to="/wallet/deposit" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Deposit")}</span>
                </Link>
                <Link className={`${path === "/wallet/withdrawal" ? "mm-active menu-active" : ""}`} to="/wallet/withdrawal" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Withdrawal")}</span>
                </Link>
                {/* <Link className={`${path === "/wallet/internalTransfer" ? "mm-active menu-active" : ""}`} to="/wallet/internalTransfer" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Internal Transfer")}</span>
                </Link> */}
                <Link className={`${path === "/wallet/history" ? "mm-active menu-active" : ""}`} to="/wallet/history" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Transaction History")}</span>
                </Link>
                <Link className={`${path === "/wallet/bankDepositHistory" ? "mm-active menu-active" : ""}`} to="/wallet/bankDepositHistory" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Bank Deposit History")}</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${team.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link className="has-arrow ai-icon" to="#">
              <FontAwesomeIcon icon={faUsers} className="menu-icon" />
              <span className="nav-text forms">
                {translate(props.lang, "Team")}
              </span>
            </Link>
            <ul className={`${team.includes(path) ? "mm-show" : ""}`}>
              <li>
                <Link className={`${path === "/team" ? "mm-active menu-active" : ""}`} to="/team" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Overview")}</span>
                </Link>
                <Link className={`${path === "/team/total-team-investment" ? "mm-active menu-active" : ""}`} to="/team/team-referral-tree" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Team Referral Tree")}</span>
                </Link>
                {/* <Link className={`${path === "/team/group-investment-summary" ? "mm-active menu-active" : ""}`} to="/team/group-investment-summary" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Group Investment Summary")}</span>
                </Link> */}
                <Link className={`${path === "/team/referral" ? "mm-active menu-active" : ""}`} to="/team/referral" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Referral Link")}</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${profile.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link to="/profile" className="ai-icon" onClick={() => closeMenu()}>
              <FontAwesomeIcon icon={faUser} className="menu-icon" />
              <span className="nav-text">{translate(props.lang, "Profile")}</span>
            </Link>
          </li>
          <li className={`${setting.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link to="/setting" className="has-arrow ai-icon">
              <FontAwesomeIcon icon={faCog} className="menu-icon" />
              <span className="nav-text" >{translate(props.lang, "Settings")}</span>
            </Link>
            <ul className={`${setting.includes(path) ? "mm-show" : ""}`}>
              <li>
                <Link className={`${(path === "/setting" || path === "/setting/change-password" || path === "/setting/change-email" || path === "/setting/change-transaction-password") ? "mm-active menu-active" : ""}`} to="/setting" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Overview")}</span>
                </Link>
                <Link className={`${path === "/setting/kyc" ? "mm-active menu-active" : ""}`} to="/setting/kyc" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "KYC")}</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* <li className={`${gasfreecampaign.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
            <Link to="/gasfreecampaign" className="has-arrow ai-icon">
                <FontAwesomeIcon icon={faBullhorn} className="menu-icon" />
              <span className="nav-text" >{translate(props.lang, "Gas Fee Campaign")}</span>
            </Link>
            <ul>
              <li>
                <Link className={`${(path === "/gasfreecampaign/register") ? "mm-active menu-active" : ""}`} to="/gasfeecampaign/register" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "Register")}</span>
                </Link>
                <Link className={`${path === "/gasfreecampaign/kyc" ? "mm-active menu-active" : ""}`} to="/gasfeecampaign/status" onClick={() => closeMenu()}>
                  <span className="submenu-text">{translate(props.lang, "status")}</span>
                </Link>
              </li>
            </ul>
          </li> */}
          <hr className="mx-3 d-lg-none d-block" />
          <li className={`d-flex d-lg-none ${language.includes(path) ? "mm-active mm-parent-active" : ""}`}>
            <Link to="/language" className="ai-icon" onClick={() => closeMenu()}>
              {props.lang === 'EN' ? (<img alt="images" width={25} src={require('../../../images/language/en.png').default} />) : (
                <img alt="images" width={25} src={require('../../../images/language/cn.png').default} />
              )}
              <span className="nav-text">{props.lang === "EN" ? "English" : "中文（简体）"}</span>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(SideBar);
